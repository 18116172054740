<template>
  <v-container fluid class="pa-0 ma-0">
    <div class="mx-4" v-if="isMobile">
      <main-layout />
      <!-- <welcome v-if="showWelcome"></welcome> -->
      <custom-modal
        v-if="validated"
        :text="'email_validated_text'"
        :title="'email_validated_title'"
      />
      <v-form ref="form" v-model="formValid" lazy-validation>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="6" md="4" class="pa-0">
            <h2
              class="ml-2 font-weight-black"
              :class="!validated ? 'my-4' : 'mb-4'"
            >
              {{ validated ? $t("register.fill_in") : $t("register.title") }}
            </h2>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="6" md="4" class="pa-0">
            <v-text-field
              :label="$t('field.email')"
              color="primary"
              :rules="emailRules"
              :disabled="this.info.email === null"
              validate-on-blur
              required
              outlined
              v-model="fields.email"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="6" md="4" class="pa-0">
            <v-text-field
              :type="show1 ? 'text' : 'password'"
              :disabled="this.info.email === null"
              :persistent-hint="true"
              :hint="$t('validation.password')"
              validate-on-blur
              :label="$t('field.password')"
              outlined
              v-model="fields.password"
              :rules="required"
            >
              <v-icon slot="append" color="primary" @click="show1 = !show1">{{
                show1 ? "mdi-eye" : "mdi-eye-off"
              }}</v-icon>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center" v-if="validated">
          <v-col cols="10" sm="6" md="4" class="pa-0 mo-0 mt-2 mb-4">
            <a @click="redirectChangePassword" style="opacity: 0.6">{{
              $t("password.forget")
            }}</a>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center" v-if="!validated">
          <v-col cols="12" sm="6" md="4" class="pa-0">
            <v-radio-group v-model="fields.type" :rules="required">
              <v-radio :value="candidateUserType">
                <template v-slot:label>
                  <div class="primary--text text-subtitle-1">
                    {{ $t("candidate.im") }}
                  </div>
                </template></v-radio
              >
              <v-radio :value="pharmacyUserType">
                <template v-slot:label>
                  <div class="primary--text text-subtitle-1">
                    {{ $t("pharmacy.im") }}
                  </div>
                </template></v-radio
              >
            </v-radio-group>
          </v-col>
        </v-row>
      </v-form>
      <v-row class="mx-4 mb-4" justify="center">
        <v-col cols="8" md="3" sm="3" class="pa-0 mt-2 mr-0 text-center">
          <div>
            <v-btn
              :disabled="!formValid"
              color="secondary"
              style="text-capitalize"
              block
              large
              rounded
              @click.prevent="onClickCreateAccount"
            >
              {{
                !validated ? $t("account.validate") : $t("account.fill_data")
              }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-footer v-if="!validated" color="transparent" class="primary--text">
        <v-row class="mb-2">
          <v-col class="text-center">
            <span class="text-subtitle-1"
              >{{ $t("register.already") }}
              <br />
              <a
                class="font-weight-bold text-decoration-underline"
                @click="redirectLogin"
                >{{ $t("login.link") }}</a
              ></span
            >
          </v-col>
        </v-row>
      </v-footer>
    </div>
    <div v-if="!isMobile" class="ma-0 pa-0">
      <v-row
        class="background-image pa-0 ma-0 ba-0 height-row-1"
        justify="center"
      >
        <v-row justify="center" class="height-principal">
          <v-col cols="10" class="pa-0 ma-0 ba-0 mt-10 title-height">
            <v-row class="pa-0 ma-0 ba-0" justify="start">
              <div class="d-flex pt-10">
                <v-img
                  alt="ApoTalent Logo"
                  class="shrink mr-2"
                  contain
                  src="@/assets/apotalent/Isotipo white.png"
                  transition="scale-transition"
                  width="95"
                />
                <v-img
                  alt="ApoTalent Name"
                  class="shrink mt-1"
                  contain
                  src="@/assets/apotalent/ApoTalent.svg"
                  transition="scale-transition"
                  width="170"
                />
              </div>
            </v-row>
            <v-row class="px-0 pt-8 ma-0 ba-0 body-height" justify="center">
              <v-col cols="5" class="ma-0 pa-0">
                <p class="primary--text title-styles mt-10">¡Bienvenido!</p>
                <p class="black--text title-body mt-10 pr-10">
                  Te damos la bienvenida a tu plataforma profesional de talento
                  para la farmacia comunitaria.
                </p>
              </v-col>
              <v-col cols="5" class="ma-0 pa-0">
                <div class="white rounded-lg pa-2">
                  <v-form ref="form" v-model="formValid" lazy-validation>
                    <v-row class="pa-0 ma-0" justify-sm="center">
                      <v-col cols="9" class="pa-0">
                        <p class="title-action my-8">
                          {{ $t("register.title") }}
                        </p>
                        <v-text-field
                          :label="$t('field.email')"
                          color="primary"
                          :rules="emailRules"
                          validate-on-blur
                          required
                          outlined
                          v-model="fields.email"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0" justify-sm="center">
                      <v-col cols="9" class="pa-0">
                        <v-text-field
                          :type="show1 ? 'text' : 'password'"
                          name="input-10-1"
                          :hint="$t('validation.password')"
                          :label="$t('field.password')"
                          outlined
                          :hide-details="false"
                          @keypress.enter="onLogin"
                          v-model="fields.password"
                        >
                          <v-icon
                            slot="append"
                            color="primary"
                            @click="show1 = !show1"
                            >{{ show1 ? "mdi-eye" : "mdi-eye-off" }}</v-icon
                          >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row
                      class="pa-0 ma-0"
                      justify-sm="center"
                      v-if="!validated"
                    >
                      <v-col cols="9" class="pa-0">
                        <v-radio-group v-model="fields.type" :rules="required">
                          <v-radio :value="candidateUserType">
                            <template v-slot:label>
                              <div class="primary--text text-subtitle-1">
                                {{ $t("candidate.im") }}
                              </div>
                            </template></v-radio
                          >
                          <v-radio :value="pharmacyUserType">
                            <template v-slot:label>
                              <div class="primary--text text-subtitle-1">
                                {{ $t("pharmacy.im") }}
                              </div>
                            </template></v-radio
                          >
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row class="mx-4 my-6" justify="center">
                    <v-col cols="8" class="pa-0 mt-2 mr-0 text-center">
                      <div>
                        <v-btn
                          color="secondary"
                          style="text-capitalize text-h6 font-weight-light"
                          block
                          large
                          rounded-1
                          :disabled="!formValid"
                          @click.prevent="onClickCreateAccount"
                        >
                          {{
                            !validated
                              ? $t("account.validate")
                              : $t("account.fill_data")
                          }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div class="center py-4">
                  <p class="text-center">
                    {{ $t("register.already") }}
                    <a class="wrap-under" @click="redirectRegister">{{
                      $t("login.link")
                    }}</a>
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-row>
      <v-row class="height-footer">
        <v-col cols="12" class="pa-0 mx-0 my-0 d-flex white">
          <div class="wrap-width ma-0 d-flex justify-end">
            <div class="wrap-text py-6 pl-4 pr-2">
              <p class="title-selection">Para candidatos</p>
              <p class="text-selection">
                En Apotalent, te ayudamos a encontrar el empleo en la farmacia
                más adecuada para ti
              </p>
              <ul class="text-selection">
                <li>Potenciamos tu perfil</li>
                <li>Sacamos tu mejor versión</li>
              </ul>
            </div>
          </div>
          <div class="wrap-width ma-0 d-flex justify-start">
            <div class="wrap-text py-6 pl-8 pr-2">
              <p class="title-selection">Para farmacias</p>
              <p class="text-selection">
                En Apotalent, ponemos a tu disposición dos servicios de
                preselección:
              </p>
              <ul class="text-selection">
                <li>Alquimia, selección automatizada</li>
                <li>Magister, análisis psicosocial de candidatos</li>
              </ul>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          class="bg-green-footer px-0 pt-2 pb-6 ma-0 d-flex justify-center"
        >
          <ul class="d-flex">
            <ol class="pt-5">
              <a class="footer-options" @click="redirectFaqs"
                >Términos y condiciones</a
              >
            </ol>
            <ol class="pt-5">
              <a class="footer-options" @click="redirectFaqs"
                >Políticas de privacidad</a
              >
            </ol>
            <ol class="pt-5">
              <a class="footer-options" @click="redirectContact">FAQS</a>
            </ol>
            <ol class="pt-5">
              <a class="footer-options" @click="redirectContact">Ayuda</a>
            </ol>
            <!--  <ol class="footer-options wrap-languages pt-3">
              <v-select
                v-model="select"
                class="wrap-options pa-0"
                :items="items"
                item-text="language"
                item-value="flat"
                dense
                return-object
                single-line
              ></v-select>
            </ol> -->
          </ul>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import config from "@/config";
import { mapGetters, mapActions } from "vuex";
// import Welcome from "../modals/Welcome.vue";
import CustomModal from "../modals/CustomModal.vue";
import MainLayout from "@/components/layouts/Main.vue";
export default {
  components: {
    // Welcome,
    MainLayout,
    CustomModal,
  },
  props: {
    validated: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show1: false,
      // showWelcome: false,
      formValid: true,
      // user heredado del desktop
      user: {
        email: "",
        password: "",
      },
      select: { language: "Español", flat: "ES" },
      items: [
        { language: "Español", flat: "ES" },
        { language: "Inglés", flat: "EN" },
        { language: "Portugués", flat: "PO" },
        { language: "Catalán", flat: "CA" },
      ],
      fields: {
        type: null,
        email: null,
        password: null,
      },
      required: [(v) => !!v || this.$i18n.t("validation.required")],
      emailRules: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) => /.+@.+/.test(v) || this.$i18n.t("validation.email_format"),
      ],
    };
  },
  mounted() {
    this.enableLoading();
    this.fields["email"] = this.info.email;
    this.fields["password"] = this.info.email;
    this.disableLoading();
  },
  _computed: {
    ...mapGetters({ keyValues: "core/keyValues" }),
    ...mapGetters({ userType: "user/type" }),
    ...mapGetters({ info: "user/info" }),
    candidateUserType() {
      return config.CONSTANTS.CANDIDATE_USER_TYPE;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    pharmacyUserType() {
      return config.CONSTANTS.PHARMACY_USER_TYPE;
    },
  },
  get computed() {
    return this._computed;
  },
  set computed(value) {
    this._computed = value;
  },
  methods: {
    ...mapActions({ disableLoading: "application/disableLoading" }),
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ register: "user/registration" }),
    ...mapActions({ login: "user/login" }),
    ...mapActions({ updateInitUserType: "user/updateInitUserType" }),
    redirectLogin() {
      this.$router.push({ name: "login" });
    },
    redirectChangePassword() {
      this.$router.push({ name: "forgot-password" });
    },
    async onClickCreateAccount() {
      if (this.$refs.form.validate()) {
        if (this.validated) this.$emit("createdAccount", this.fields);
        else {
          try {
            await this.updateInitUserType(this.fields.type);
            if (!this.info.id) {
              await this.login(this.fields);
            }
            if (!this.userType || this.userType === "default") {
              this.$router.push({ name: "register-validated" });
            } else this.$router.push({ name: "offers" });
          } catch (err) {
            try {
              await this.register(this.fields);
              this.$router.push({ name: "register-validated" });
            } catch (error) {
              Object.values(error.response.data).forEach((elm) => {
                this.$notify({
                  title: this.$i18n.tc("account.register_error", 1),
                  text: elm.join(","),
                  type: "error",
                });
              });
            }
          }
        }
      }
    },
    updateCandidateInfo(data) {
      Object.assign(this.candidate, data);
    },
    formatDate(date) {
      return date.split("/").reverse().join("-");
    },
    redirectRegister() {
      this.$router.push({ name: "login" });
    },
    redirectContact() {
      this.$router.push({ name: "contact" });
    },
    redirectFaqs() {
      this.$router.push({ name: "faqs" });
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined fieldset {
  border-color: #718f94 !important;
}
.v-btn border {
  border-color: #718f94;
}
.background-image {
  background-image: url("~@/assets/apotalent/loginapo.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 1000px;
  padding-top: 0px !important;
  border: 0px !important;
  margin: 0px !important;
  background-position: top center;
}
.title-styles {
  font-size: 45px;
}
.title-body {
  font-size: 25px;
}
.title-heigth {
  height: 5%;
}
.body-height {
  height: 40%;
}
.wrap-login {
  border: 3px;
  background: white;
}
.bg-green-footer {
  background: #9ab4b8;
}
.height-row-1 {
  height: 73% !important;
}
.height-row-2 {
  height: 27% !important;
}
.wrap-width {
  width: 50%;
  border-right: solid #9ab4b8 1px;
}
.wrap-text {
  width: 60%;
}
.title-selection {
  color: black;
  font-size: 24px;
  font-weight: bold;
}
.text-selection {
  font-size: 20px;
}
.footer-options {
  margin-left: 40px;
  margin-right: 40px;
  font-size: 18px;
}
.title-action {
  color: black;
  font-size: 19px;
  font-weight: bold;
}
.wrap-under {
  text-decoration: underline;
}
.wrap-languages {
  width: 200px;
}

.footer-options .v-input {
  font-size: 18px !important;
}
.footer-options {
  border: 0px !important;
}
.footer-options .v-text-field > .v-input__control > .v-input__slot::before {
  border-style: none !important;
}
.v-text-field--outlined >>> fieldset {
  border-color: #718f94;
}
.height-principal {
  height: 50% !important;
}
.height-footer {
  position: absolute !important;
  height: 20% !important;
}
</style>
